import PropTypes from "prop-types";
// @mui
import {Button, InputAdornment, Stack, TextField} from '@mui/material';
import Iconify from "../../components/Iconify";
import {useLocales} from "../../locales";
// components


LinesListTableToolbar.propTypes = {
    isFiltered: PropTypes.bool,
    filters: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onResetFilter: PropTypes.func.isRequired,
    isPreview: PropTypes.bool,
};

export default function LinesListTableToolbar({isPreview, isFiltered, filters, onFilterChange, onResetFilter}) {
    const {translate} = useLocales();

    const {name, licenceName, operatorName, issuedFor} = filters;

    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{px: 2.5, py: 3}}
        >
            {isPreview && <TextField
                size={"small"}
                fullWidth
                value={operatorName}
                onChange={(e) => onFilterChange("operatorName", e.target.value)}
                placeholder={translate("carrierName")}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />}

            <TextField
                size={"small"}
                fullWidth
                value={name}
                onChange={(e) => onFilterChange("name", e.target.value)}
                placeholder={translate("lineName")}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            {/*<TextField*/}
            {/*    size={"small"}*/}
            {/*    fullWidth*/}
            {/*    value={name}*/}
            {/*    onChange={(e) => onFilterChange("issuedFor", e.target.value)}*/}
            {/*    placeholder={translate("issuedFor")}*/}
            {/*    InputProps={{*/}
            {/*        startAdornment: (*/}
            {/*            <InputAdornment position="start">*/}
            {/*                <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>*/}
            {/*            </InputAdornment>*/}
            {/*        ),*/}
            {/*    }}*/}
            {/*/>*/}

            <TextField
                size={"small"}
                fullWidth
                value={licenceName}
                onChange={(e) => onFilterChange("licenceName", e.target.value)}
                placeholder={translate("licenceName")}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            {isFiltered && (
                <Button
                    color="error"
                    sx={{flexShrink: 0}}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="eva:trash-2-outline"/>}
                >
                    {translate("clear")}
                </Button>
            )}
        </Stack>
    )
}
