import {format, formatDistanceToNow, getTime} from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
    const fm = newFormat || 'dd.MM.yyyy';

    return date ? format(new Date(date), fm) : '';
}

export function fDateNewDate(date) {
    return date ? format(new Date(date), 'dd.MM.yyyy') : ''
}

export function fDateTime(date, newFormat) {
    const fm = newFormat || 'dd.MM.yyyy HH:mm:ss';

    return date ? format(new Date(date), fm) : '';
}

export function fDateTimeStartOfDay(date) {
    if (!date) return '';

    const parsedDate = new Date(date);
    parsedDate.setUTCHours(0, 0, 0, 0);

    return parsedDate.toISOString();
}


export function fTimestamp(date) {
    return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
    return date
        ? formatDistanceToNow(new Date(date), {
            addSuffix: true,
        })
        : '';
}
