import {customAxiosBE} from "../config";
import {parsePaginationParams} from "../../utils/other";
import axios from "axios";

export async function getAllPlatformsAPI(payload) {
    const parsedParams = parsePaginationParams(payload)
    return customAxiosBE.get(`/platforms?${parsedParams}`)
}

export async function getAllPlatformsByStationIdAPI(stationId) {
    return customAxiosBE.get(`/platforms/stationId/${stationId}`)
}
export async function fetchPlatformsBusynessAPI(stationId, platformId, sortOrder) {
    const parsedParams = parsePaginationParams(sortOrder)
    let params = "";
    if(platformId !== undefined){
        params = `&platformId=${platformId}`
    }
    return customAxiosBE.get(`/platforms/stationId/${stationId}/busyness?${parsedParams}${params}`)
}

export async function insertNewPlatformAPI(payload) {
    return customAxiosBE.post(`/platforms`, payload)
}

export async function updatePlatformAPI(payload) {
    return customAxiosBE.put(`/platforms`, payload)
}

export async function deletePlatformAPI(id) {
    return customAxiosBE.delete(`/platforms/${id}`,)
}

export const searchPlatformAPI = async (data, cancelToken) => {
    let response
    const {platformName, stationName} = data
    const params = parsePaginationParams(data)
    await customAxiosBE
        .post(`/platforms/search?${params}`, {
            platformName: platformName,
            stationName: stationName,
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}
