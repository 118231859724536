import {useSettingsContext} from "../../../components/settings";
import {Button, Card, Container, Stack, Table, TableBody, TableContainer} from "@mui/material";
import {PATH_DASHBOARD} from "../../../routes/path";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import {useEffect, useState} from "react";
import {TableHeadCustom, TablePaginationCustom, TableSkeleton, useTable} from "../../../components/table";
import Scrollbar from "../../../components/Scrollbar";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Helmet} from "react-helmet-async";
import {useLocales} from "../../../locales";
import {dispatch, useSelector} from "../../../redux/store";
import {debounceSearch} from "../../../api/searchDebounce";
import {debounce} from "lodash";
import ActionsValueTableRow from "./ActionsValueTableRow";
import ActionsValueTableToolbar from "./ActionsValueTableToolbar";
import {searchActionsSaleType} from "../../../redux/slices/salesActionType";
import {searchActions, specialActionsLoading} from "../../../redux/slices/actions";
import {searchActionsAPI} from "../../../api/actions";

// ----------------------------------------------------------------------

const TABLE_HEAD = translate => [
    {id: "salesActionType.name", label: translate('name'), align: "left", width: '100%'},
    {id: "salesActionType.isAbsolute", label: translate("type"), align: "left", width: 160},
    {id: "amount", label: translate("amount"), align: "left", width: 160},
    {id: "vat", label: translate("vat"), align: "left", width: 80},
    {id: "dateFrom", label: translate("dateFrom"), align: "left", width: 200},
    {id: "dateTo", label: translate("dateTo"), align: "left", width: 200},
    {id: "", align: "right", width: 50, sortable: false}
];
export default function ActionsValue() {
    const {translate} = useLocales();
    const {enqueueSnackbar} = useSnackbar();

    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage
    } = useTable({
        defaultOrderBy: "id"
    });

    const {themeStretch} = useSettingsContext();

    const navigate = useNavigate();

    const {loadingActions, foundActions} = useSelector(state => state.actions)

    const [filters, setFilters] = useState({
        filterName: "",
        dateFrom: null,
        dateTo: null,
    });

    const isFiltered = Object.entries(filters).some(([key, value]) => {
        if (key === "filterStatus") {
            return value !== "SVI";
        }
        if (value === null || value === undefined) {
            return false;
        }
        if (typeof value === "string") {
            return value?.trim() !== "";
        }
        return true;
    });

    useEffect(() => {
        dispatch(specialActionsLoading(true))
        debounceSearchAction({
            ...filters,
            page,
            rowsPerPage,
            order,
            orderBy
        });
    }, [filters, page, rowsPerPage, order, orderBy]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyboardPress);

        return () => {
            window.removeEventListener('keydown', handleKeyboardPress);
        };
    }, []);


    const searchAction = async (value) => {
        try {
            const response = await debounceSearch(searchActionsAPI, value);
            dispatch(searchActions(response))
        } catch (error) {
            enqueueSnackbar(translate("errorOnServer"), {variant: "error"})
        }
    };

    const debounceSearchAction = debounce(searchAction, 300);

    const handleEditRow = (data) => {
        const {id} = data
        if (id) {
            navigate(PATH_DASHBOARD.actions.actionValueListEdit(data.id));
        }
    };

    const handleFilterChange = (field, value) => {
        setPage(0);
        setFilters((prev) => ({...prev, [field]: value}));
    };


    const handleResetFilter = () => {
        setPage(0);
        setFilters({
            filterName: ""
        });
    };


    const handleKeyboardPress = (e) => {
        if (e.key === 'Insert') {
            e.preventDefault();
            navigate(PATH_DASHBOARD.actions.actionValueNew)

        }
        if (e.key === 'Escape') {
            e.preventDefault();
            handleResetFilter();
        }
    }

    return (
        <>
            <Helmet>
                <title>BUS Soft - {translate('actionsValue')}</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : "xl"}>
                <CustomBreadcrumbs
                    heading={translate("actionsValueList")}
                    links={[
                        {name: translate('home'), href: PATH_DASHBOARD.general.app},
                        {name: translate('actionsValue'), href: PATH_DASHBOARD.actions.actionValueList},
                        {name: translate('list')}
                    ]}
                    action={
                        <Stack spacing={2}
                               alignItems="center"
                               direction={{
                                   xs: 'column',
                                   sm: 'row',
                               }}
                               sx={{px: 2.5, py: 3}}>
                            <Button
                                to={PATH_DASHBOARD.actions.actionValueNew}
                                component={RouterLink}
                                variant="outlined"
                                sx={{width: 200}}>
                                {translate("add")} [Insert]
                            </Button>
                            <Button
                                disabled={!isFiltered}
                                variant="outlined"
                                sx={{width: 200}}
                                onClick={handleResetFilter}>
                                {translate("cancel")} [Esc]
                            </Button>
                        </Stack>
                    }
                />

                <Card>
                    <ActionsValueTableToolbar
                        isFiltered={isFiltered}
                        filters={filters}
                        onFilterChange={handleFilterChange}
                        onResetFilter={handleResetFilter}
                    />

                    <TableContainer sx={{position: "relative", overflow: "unset"}}>
                        <Scrollbar>
                            <Table size={dense ? "small" : "medium"} sx={{minWidth: 960}}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD(translate)}
                                    rowCount={foundActions?.content?.length}
                                    onSort={onSort}
                                />

                                <TableBody>
                                    {loadingActions ?
                                        Array.from({length: rowsPerPage}, (_, i) => (
                                            <TableSkeleton key={i} columns={8}/>
                                        ))
                                        :
                                        foundActions?.content?.map((row, index) =>
                                            <ActionsValueTableRow
                                                enqueueSnackbar={enqueueSnackbar}
                                                key={index}
                                                row={row}
                                                onEditRow={() => handleEditRow(row)}
                                            />)
                                    }
                                </TableBody>

                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        loading={loadingActions}
                        count={foundActions?.totalElements || 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                        //
                        dense={dense}
                        onChangeDense={onChangeDense}
                    />
                </Card>
            </Container>
        </>
    );
}

