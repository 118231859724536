import {customAxiosBE} from "../config";
import axios from "axios";
import {parsePaginationParams} from "../../utils/other";

export async function tripViewById(id) {
    return customAxiosBE.get(`/tripView/${id}`)
}

export async function getAllTripViewAPI(data) {
    const parsedParams = parsePaginationParams(data)
    return customAxiosBE.get(`/tripView?${parsedParams}`)
}

export const searchTripViewAPI = async (data, cancelToken) => {
    let response
    const {lineName, operatorName, dateTo, dateFrom,departureTime, departureTimeFromNSTimeID} = data
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE
        .post(`/tripView/search?${parsedParams}`, {
            lineName: lineName,
            departureTimeFromNSTimeID: departureTimeFromNSTimeID,
            operatorName: operatorName,
            dateTo: dateTo,
            dateFrom: dateFrom,
            departureTime: departureTime,
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}
