import {getSessionOperator} from "../helper/session";

export const sanitizeString = (value) => (typeof value === 'string' && value.trim() === '' ? null : value);

export const getCashierName = () =>{
    const operator = getSessionOperator();
    if(operator.printText){
        return operator.printText;
    }
    return operator.username
}

export const formatQuantity = (qty) => {
    if (qty === undefined || qty === null) {
        return 0
    }
    let qtyFormat = qty
    if (typeof qty !== 'number')
        parseFloat((qtyFormat))
    let checkDecimals = qty.toString()
    if (checkDecimals.includes('.') && checkDecimals.split('.')[1].length > 3) {
        return parseFloat(parseFloat(qtyFormat).toFixed(3))
    } else {
        return parseFloat((qtyFormat))
    }
}

export const customSupplierFilter = (options, state) => {
    return options.filter(item =>
        (item.TIN && item.TIN.includes(state.inputValue)) ||
        (item.name && item.name.includes(state.inputValue)) ||
        (item.CIN && item.CIN.includes(state.inputValue))
    )
}

export function getAllCurrentTaxRates(rates) {
    let allRates = []
    for (let i = 0; i < rates.length; i++) {
        for (let j = 0; j < rates[i].taxRates.length; j++) {
            allRates.push(rates[i].taxRates[j])
        }
    }
    return allRates
}

export async function fetchBlobFromImgUrl(imageUrl) {
    const xhr = new XMLHttpRequest();

    xhr.responseType = 'blob';
    xhr.onload = (_) => {
        return (xhr.response)
    };
    xhr.open('GET', imageUrl);
    xhr.send();
}

export const parsePaginationParams = (params) => {
    const pagination = [
        {key: "page", value: params?.page || 0},
        {key: "size", value: params?.rowsPerPage || 10},
        {key: "sort", value: `${params?.orderBy || "id"},${params?.order || "asc"}`},
    ]
    return pagination.map(item => `${item.key}=${item.value}`).join('&')
}

export const clearEmptyString = (string) =>{
    if((string ||"").length > 0){
        return string
    }
    return null
}

export const parseResponseError = (error) => {
    if(typeof error === 'string'){
        return error;
    }
    if(error?.data){
        return error.data
    }
    return error;
}
