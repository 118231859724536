import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {deleteRateAPI, getAllRateAPI, getRateByIdAPI, insertRateAPI, updateRateAPI} from "../../api/rate";

export const fetchAllRates = createAsyncThunk('fetchAllRates', async (payload) => {
    return await getAllRateAPI(payload);
});

export const findAllRatesByRateCardId = createAsyncThunk('fetchAllRatesByRateCard', async (id) => {
    return await getRateByIdAPI(id);
});

export const postRate = createAsyncThunk('postRate', async (payload) => {
    return insertRateAPI(payload);
});

export const updateRate = createAsyncThunk('updateRate', async (payload) => {
    return updateRateAPI(payload);
});

export const deleteRate = createAsyncThunk('deleteRate', async (id, {rejectWithValue}) => {
    try {
        const response = await deleteRateAPI(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
});

const initialState = {
    foundRates: [],
    loadingRates: false
};

export const ratesSlice = createSlice({
    name: 'rates', initialState, reducers: {
        specialRateLoading: (state, {payload}) => {
            state.loadingRates = !!payload;
        }, searchRateSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundRates = payload;
                state.loadingRates = false;
            }
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(fetchAllRates.pending, (state) => {
                state.loadingRates = true;
            })
            .addCase(fetchAllRates.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.foundRates = payload.data;
                }
                state.loadingRates = false;
            })
            .addCase(fetchAllRates.rejected, (state) => {
                state.loadingRates = false;
            })
            .addCase(findAllRatesByRateCardId.pending, (state) => {
                state.loadingRates = true;
            })
            .addCase(findAllRatesByRateCardId.fulfilled, (state, {payload}) => {
                state.loadingRates = false;
                state.foundRates = payload.data.content;
            })
            .addCase(findAllRatesByRateCardId.rejected, (state) => {
                state.loadingRates = false;
            })
            .addCase(postRate.pending, (state) => {
                state.loadingRates = true;
            })
            .addCase(postRate.fulfilled, (state) => {
                state.loadingRates = false;
            })
            .addCase(postRate.rejected, (state) => {
                state.loadingRates = false;
            })
            .addCase(updateRate.pending, (state) => {
                state.loadingRates = true;
            })
            .addCase(updateRate.fulfilled, (state) => {
                state.loadingRates = false;
            })
            .addCase(updateRate.rejected, (state) => {
                state.loadingRates = false;
            })
            .addCase(deleteRate.fulfilled, (state, {meta}) => {
                let content = state.foundRates.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(rate => rate.id === meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                        state.foundRates.totalElements -= 1;
                        state.foundRates.numberOfElements = content.length;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
    },
});

export const ratesReducer = ratesSlice.reducer;
export const { searchRateSlice, specialRateLoading} = ratesSlice.actions;
