import {useLocales} from "../../../locales";
import {Button, InputAdornment, Stack, TextField} from "@mui/material";
import Iconify from "../../../components/Iconify";

export default function ActionsValueTableToolbar({isFiltered, filters, onFilterChange, onResetFilter}){
    const {translate} = useLocales();

    const {filterName} = filters;

    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{px: 2.5, py: 3}}
        >

            <TextField
                size={"small"}
                fullWidth
                value={filterName}
                onChange={(e) => onFilterChange("filterName", e.target.value)}
                placeholder={translate("actionName")}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            {isFiltered && (
                <Button
                    color="error"
                    sx={{flexShrink: 0}}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="eva:trash-2-outline"/>}
                >
                    {translate("clear")}
                </Button>
            )}
        </Stack>
    )
}
