// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    operatorLogin: path(ROOTS_AUTH, '/operator-login'),
    register: path(ROOTS_AUTH, '/register'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    verify: path(ROOTS_AUTH, '/verify'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/home'),
        stationServices: path(ROOTS_DASHBOARD, '/stationServices'),
        new_invoice: path(ROOTS_DASHBOARD, '/home/newInvoice'),
        invoices: path(ROOTS_DASHBOARD, '/invoices'),
        busTickets: path(ROOTS_DASHBOARD, '/busTickets'),
        settings: path(ROOTS_DASHBOARD, '/settings'),
    },
    reports: {
        root: path(ROOTS_DASHBOARD, '/reports'),
        additionalIncome: path(ROOTS_DASHBOARD, '/reports/additionalIncome'),
        overviewTicketAndOther: path(ROOTS_DASHBOARD, '/reports/overviewTicketAndOther'),
        overview: path(ROOTS_DASHBOARD, '/reports/overview'),
    },
    preview: {
        priceList: path(ROOTS_DASHBOARD, '/preview/priceList'),
        linesList: path(ROOTS_DASHBOARD, '/preview/linesList'),
        departuresList: path(ROOTS_DASHBOARD, '/preview/departuresList'),
        timesList: path(ROOTS_DASHBOARD, '/preview/timesList'),
        discountsList: path(ROOTS_DASHBOARD, '/preview/discountsList'),
        driveParkList: path(ROOTS_DASHBOARD, '/preview/driveParkList'),
        commentsList: path(ROOTS_DASHBOARD, '/preview/commentsList'),
        allotments: path(ROOTS_DASHBOARD, '/preview/allotmentList'),
        currentAllotments: path(ROOTS_DASHBOARD, '/preview/current-allotments'),
        previewDeparturesLinked: path(ROOTS_DASHBOARD, '/preview/departures-linked'),
        cancelledTripsHistory: path(ROOTS_DASHBOARD, '/preview/cancelled-trips-history'),
        ratesHistory: path(ROOTS_DASHBOARD, '/preview/rates-history')
    },
    discounts: {
        root: path(ROOTS_DASHBOARD, '/discounts'),
        list: path(ROOTS_DASHBOARD, '/discounts/list'),
        new: path(ROOTS_DASHBOARD, '/discounts/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/discounts/${id}/edit`),
    },
    privileges: {
        root: path(ROOTS_DASHBOARD, '/privileges'),
        list: path(ROOTS_DASHBOARD, '/privileges/list'),
        new: path(ROOTS_DASHBOARD, '/privileges/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/privileges/${id}/edit`),
        listDisabilities: path(ROOTS_DASHBOARD, '/privileges/disability'),
        newDisability: path(ROOTS_DASHBOARD, '/privileges/disability-new'),
        editDisability: (id) => path(ROOTS_DASHBOARD, `/privileges/${id}/editDisability`),
    },
    bus: {
        root: path(ROOTS_DASHBOARD, '/bus'),
        list: path(ROOTS_DASHBOARD, '/bus/list'),
        new: path(ROOTS_DASHBOARD, '/bus/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/bus/${id}/edit`),
    },
    priceList: {
        root: path(ROOTS_DASHBOARD, '/priceList'),
        list: path(ROOTS_DASHBOARD, '/priceList/list'),
        stationsList: path(ROOTS_DASHBOARD, '/priceList/stationsList'),
        new: path(ROOTS_DASHBOARD, '/priceList/new'),
        newStation: path(ROOTS_DASHBOARD, '/priceList/newStation'),
        stationsServicesList: path(ROOTS_DASHBOARD, '/priceList/stationsServicesList'),
        stationsServicesAdd: path(ROOTS_DASHBOARD, '/priceList/stationsServicesAdd'),
        edit: (id) => path(ROOTS_DASHBOARD, `/priceList/${id}/edit`),
        editStation: (id) => path(ROOTS_DASHBOARD, `/priceList/${id}/edit-station`),
        editStationService: (id) => path(ROOTS_DASHBOARD, `/priceList/${id}/edit-station-service`),
    },
    holidays: {
        root: path(ROOTS_DASHBOARD, '/holidays'),
        list: path(ROOTS_DASHBOARD, '/holidays/list'),
        new: path(ROOTS_DASHBOARD, '/holidays/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/holidays/${id}/edit`),
    },
    stationList: {
        root: path(ROOTS_DASHBOARD, '/stationList'),
        list: path(ROOTS_DASHBOARD, '/stationList/list'),
        new: path(ROOTS_DASHBOARD, '/stationList/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/stationList/${id}/edit`),
    },
    departuresStatusList: {
        root: path(ROOTS_DASHBOARD, '/departuresStatus'),
        list: path(ROOTS_DASHBOARD, '/departuresStatus/list')
    },
    locationList: {
        root: path(ROOTS_DASHBOARD, '/locationList'),
        list: path(ROOTS_DASHBOARD, '/locationList/list'),
        new: path(ROOTS_DASHBOARD, '/locationList/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/locationList/${id}/edit`),
    },
    linesList: {
        root: path(ROOTS_DASHBOARD, '/linesList'),
        list: path(ROOTS_DASHBOARD, '/linesList/list'),
        new: path(ROOTS_DASHBOARD, '/linesList/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/linesList/${id.id}/edit`),
    },
    departuresList: {
        root: path(ROOTS_DASHBOARD, '/departuresList'),
        list: path(ROOTS_DASHBOARD, '/departuresList/list'),
        new: path(ROOTS_DASHBOARD, '/departuresList/new'),
        linked: path(ROOTS_DASHBOARD, '/departuresList/linked'),
        departuresLinkedList: path(ROOTS_DASHBOARD, '/departuresList/departuresLinkedList'),
        edit: (id) => path(ROOTS_DASHBOARD, `/departuresList/${id}/edit`),
    },
    allotment: {
        root: path(ROOTS_DASHBOARD, '/allotment'),
        list: path(ROOTS_DASHBOARD, '/allotment/list'),
        new: path(ROOTS_DASHBOARD, '/allotment/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/allotment/${id}/edit`),
    },
    timesList: {
        root: path(ROOTS_DASHBOARD, '/timesList'),
        list: path(ROOTS_DASHBOARD, '/timesList/list'),
        new: path(ROOTS_DASHBOARD, '/timesList/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/timesList/${id}/edit`),
    },
    actions: {
        root: path(ROOTS_DASHBOARD, '/actionsList'),
        list: path(ROOTS_DASHBOARD, '/actionsList/list'),
        new: path(ROOTS_DASHBOARD, '/actionsList/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/actionsList/${id}/edit`),
        actionValueList: path(ROOTS_DASHBOARD, '/actionsList/actionValueList'),
        actionValueNew: path(ROOTS_DASHBOARD, '/actionsList/actionValueNew'),
        actionValueListEdit: (id) => path(ROOTS_DASHBOARD, `/actionsList/${id}/actionValueListEdit`),
    },
    monthlyTicketsList: {
        root: path(ROOTS_DASHBOARD, '/monthlyTicketsList'),
        list: path(ROOTS_DASHBOARD, '/monthlyTicketsList/list'),
        new: path(ROOTS_DASHBOARD, '/monthlyTicketsList/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/monthlyTicketsList/${id}/edit`),
    },
    customers: {
        root: path(ROOTS_DASHBOARD, '/customers'),
        list: path(ROOTS_DASHBOARD, '/customers/list'),
        new: path(ROOTS_DASHBOARD, '/customers/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/customers/${id.uid}/edit`),
    },
    carriers: {
        root: path(ROOTS_DASHBOARD, '/carriers'),
        list: path(ROOTS_DASHBOARD, '/carriers/list'),
        new: path(ROOTS_DASHBOARD, '/carriers/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/carriers/${id}/edit`),
    },
    otpravnik: {
        root: path(ROOTS_DASHBOARD, '/otpravnik'),
        publisher: path(ROOTS_DASHBOARD, '/otpravnik/publisher'),
        other: path(ROOTS_DASHBOARD, '/otpravnik/other'),
        list: path(ROOTS_DASHBOARD, '/otpravnik/list'),
        new: path(ROOTS_DASHBOARD, '/otpravnik/new'),
        unusedCameraEvents: path(ROOTS_DASHBOARD, '/otpravnik/unused'),
        newBIS: path(ROOTS_DASHBOARD, '/otpravnik/new-bis'),
        edit: (id) => path(ROOTS_DASHBOARD, `/otpravnik/${id.uid}/edit`),
    },
    scheduledDepAndArr: {
        root: path(ROOTS_DASHBOARD, '/scheduledDepAndArr'),
        list: path(ROOTS_DASHBOARD, '/scheduledDepAndArr/list'),
    },
    peronization: {
        root: path(ROOTS_DASHBOARD, '/peronization'),
        list: path(ROOTS_DASHBOARD, '/peronization/list'),
        price: path(ROOTS_DASHBOARD, '/peronization/price'),
        new: path(ROOTS_DASHBOARD, '/peronization/new'),
    },
    parking: {
        root: path(ROOTS_DASHBOARD, '/parking'),
        list: path(ROOTS_DASHBOARD, '/parking/list'),
    },
    receivingShipping: {
        root: path(ROOTS_DASHBOARD, '/receivingShipping'),
        list: path(ROOTS_DASHBOARD, '/receivingShipping/list'),
    },
    carrierReport: {
        root: path(ROOTS_DASHBOARD, '/carrierReport'),
        edit: path(ROOTS_DASHBOARD, '/carrierReport/edit'),
        sumarized: path(ROOTS_DASHBOARD, '/carrierReport/sumarized'),
        detailed: path(ROOTS_DASHBOARD, '/carrierReport/Detailed'),
        tickets: path(ROOTS_DASHBOARD, '/carrierReport/Tickets'),
        allTickets: path(ROOTS_DASHBOARD, '/carrierReport/all-tickets'),
        returnTickets: path(ROOTS_DASHBOARD, '/carrierReport/ReturnTickets'),
        validatedTickets: path(ROOTS_DASHBOARD, '/carrierReport/ValidatedTickets'),
        privilegesTickets: path(ROOTS_DASHBOARD, '/carrierReport/PrivilegesTickets'),
    },
    commissionCalculation: {
        root: path(ROOTS_DASHBOARD, '/commissionCalculation'),
        list: path(ROOTS_DASHBOARD, '/commissionCalculation/list'),
        incomeForStation: path(ROOTS_DASHBOARD, '/commissionCalculation/incomeForStation'),
    },
    insurance: {
        root: path(ROOTS_DASHBOARD, '/insurance'),
        list: path(ROOTS_DASHBOARD, '/insurance/list'),
        new: path(ROOTS_DASHBOARD, '/insurance/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/insurance/${id.uid}/edit`),
    },
    platforms: {
        root: path(ROOTS_DASHBOARD, '/platforms'),
        list: path(ROOTS_DASHBOARD, '/platforms/list'),
        new: path(ROOTS_DASHBOARD, '/platforms/new'),
        busyness: path(ROOTS_DASHBOARD, '/platforms/busyness'),
        busynessBuilder: (id) => path(ROOTS_DASHBOARD, `/platforms/busyness/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/platforms/${id}/edit`),
    },
    operators: {
        root: path(ROOTS_DASHBOARD, '/operators'),
        list: path(ROOTS_DASHBOARD, '/operators/list'),
        new: path(ROOTS_DASHBOARD, '/operators/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/operators/${id}/edit`),
    },
    comments: {
        root: path(ROOTS_DASHBOARD, '/comments'),
        list: path(ROOTS_DASHBOARD, '/comments/list'),
        new: path(ROOTS_DASHBOARD, '/comments/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/comments/${id.uid}/edit`),
    },
    admin: {
        root: path(ROOTS_DASHBOARD, '/admin'),
        routes: path(ROOTS_DASHBOARD, '/routes')
    },
    barriers: {
        root: path(ROOTS_DASHBOARD, '/barriers'),
        list: path(ROOTS_DASHBOARD, '/barriers/list'),
        partners: path(ROOTS_DASHBOARD, '/barriers/partners'),
    }
};

