import {customAxiosBE} from "../config";
import axios from "axios";
import {parsePaginationParams} from "../../utils/other";
import moment from "moment";

// OVO SU CARRIERS
export async function getAllAllotmentsAPI(params) {
    const parsedParams = parsePaginationParams(params)
    return customAxiosBE.get(`/allotment?${parsedParams}`)
}

export async function numberOfAllotmentByTripApi(tripId, journeyDate) {
    const dateFrom = moment(journeyDate).format("YYYY-MM-DD");
    const dateTo = moment(journeyDate).format("YYYY-MM-DD");
    return customAxiosBE.get(`/allotment/count/trip/${tripId}?dateFrom=${dateFrom}&dateTo=${dateTo}`)
}

export async function allotmentsPreview(obj) {
    //2024-08-20
    const {date} = obj
    return customAxiosBE.get(`/dayTripAllotment/byJourneyDate/?journeyDate=${date}`)
}

export async function getAllAllotmentsByIdAPI(tripHasVehicleId) {
    return customAxiosBE.get(`/allotment/tripHasVehicleId/${tripHasVehicleId}`)
}

export async function getAllAllotmentsByTripIdAPI(tripId) {
    return customAxiosBE.get(`/allotment/tripId/${tripId}`)
}

export async function getLatestIssuedTicketByJourneyDate(tripId) {
    return customAxiosBE.get(`/allotment/getLatestIssuedTicketByJourneyDate/${tripId}`)
}

export async function getAllAllotmentsByLineIdAPI(lineId) {
    return customAxiosBE.get(`/allotment/lineId/${lineId}`)
}

export async function postLinkedApi(data) {
    return customAxiosBE.post(`/allotment/kalem/trips`,data)
}

export async function postLinkedTripIdsApi(tripIds) {
    return customAxiosBE.post(`/allotment/kalem/trip-links`,tripIds)
}

export async function deleteAllotmentById(id) {
    return customAxiosBE.delete(`/allotment/delete/${id}`)
}

export async function getAllAllotmentsByTripHasVehicleId({tripHasVehicleId, dateFrom, dateTo}) {
    return customAxiosBE.get('/allotment/tripHasVehicle', {
        params: {
            tripHasVehicleId,
            dateFrom,
            dateTo
        }
    });
}


export const searchAllotmentAPI = async (data, cancelToken) => {
    let response
    const {name, operatorName, operatorId, isInstant, isOld, isActive,
        dateTo, dateFrom,} = data
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE
        .post(`allotment/search?${parsedParams}`,
            {
                lineName: name,
                operatorName: operatorName,
                dateTo: dateTo ? moment.utc(dateTo).format("YYYY-MM-DD") : null,
                dateFrom: dateFrom ? moment.utc(dateFrom).format("YYYY-MM-DD") : null,
                operatorId: operatorId || null,
                isInstant: isInstant === 1 ? null : isInstant,
                isOld: isOld === 1 ? null : isOld,
                isActive: isActive === 1 ? null : isActive
            }, {
                cancelToken
            })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}


export const insertAllotmentAPI = async (data) => {
    try {
        return await customAxiosBE.post(`/allotment/`, data);
    } catch (e) {
        throw e;
    }
};

export const insertInstantAllotmentAPI = async (data) => {
    try {
        return await customAxiosBE.post(`/allotment/instant`, data);
    } catch (e) {
        throw e;
    }
};

export const updateAllotmentAPI = (data) => {
    return customAxiosBE.put(`/allotment/${data.tripHasVehicleId}`, data);
}

export const deleteAllotmentAPI = async (id) => {
    return customAxiosBE.delete(`/allotment/${id}`);
}

export const getAllotmentByIDAPI = async (id) => {
    return customAxiosBE.get(`/allotment/${id}`);
}

export const activateAllotmentAPI = async (id) => {
    return customAxiosBE.post(`/allotment/${id}/activate`);
}
