import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {
    deleteRFIDCardAPI,
    extendRFIDCardAPI,
    getAllRFIDAPI,
    insertRFIDActionAPI,
    insertRFIDAPI,
    updateRFIDCardAPI
} from "../../api/rfid";
import moment from "moment/moment";
import {parsePaginationParams} from "../../utils/other";

export const fetchAllRFIDCard = createAsyncThunk('fetchAllRFIDCard', async (payload) => {
    const {filter, params} = payload;
    let validFrom = filter.validFrom ? filter.validFrom : null;
    let validTo = filter.validTo ? filter.validTo : null;
    if (validFrom) {
        validFrom = moment(validFrom).format("DD.MM.YYYY")
    }
    if (validTo) {
        validTo = moment(validTo).format("DD.MM.YYYY")
    }
    const parsedParams = parsePaginationParams(params)
    return await getAllRFIDAPI({
        ...filter,
        validFrom,
        validTo
    }, parsedParams)
})

export const insertNewRFIDCard = createAsyncThunk('insertNewRFIDCard', async (data) => {
    return await insertRFIDAPI(data)
})

export const insertNewRFIDCardAction = createAsyncThunk('insertNewRFIDCardAction', async (data, {rejectWithValue}) => {
    try {
        const response = await insertRFIDActionAPI(data);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const extendRFIDCard = createAsyncThunk('extendRFIDCard', async (data) => {
    return await extendRFIDCardAPI(data)
})

export const deleteRFIDCard = createAsyncThunk('deleteRFIDCard', async (cardName,{rejectWithValue}) => {
    try {
        const response = await deleteRFIDCardAPI(cardName);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const updateRFIDCard = createAsyncThunk('updateRFIDCard', async (data,{rejectWithValue}) => {
    try {
        const response = await updateRFIDCardAPI(data);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})


const initialState = {
    allRFIDCards: {
        content: [],
        totalElements: 0
    },
    foundRFIDCards: [],
    loadingRFIDCards: false,
}

export const RFIDSlice = createSlice({
    name: 'rfid',
    initialState,

    reducers: {
        specialLoading: (state, { payload }) => {
            if (payload) {
                state.loadingRFIDCards = payload;
            }
        },
        searchRFIDCard: (state, { payload }) => {
            if (payload?.content) {
                state.foundRFIDCards = payload;
                state.loadingRFIDCards = false;
            }
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRFIDCard.pending, (state) => {
                state.loadingRFIDCards = true;
            })
            .addCase(fetchAllRFIDCard.fulfilled, (state, { payload }) => {
                state.allRFIDCards = payload.data;
                state.foundRFIDCards = payload.data;
                state.loadingRFIDCards = false;
            })
            .addCase(fetchAllRFIDCard.rejected, (state) => {
                state.loadingRFIDCards = false;
            })
            .addCase(insertNewRFIDCard.pending, (state) => {
                state.loadingRFIDCards = true;
            })
            .addCase(insertNewRFIDCard.fulfilled, (state) => {
                state.loadingRFIDCards = false;
            })
            .addCase(insertNewRFIDCard.rejected, (state) => {
                state.loadingRFIDCards = false;
            })
            .addCase(insertNewRFIDCardAction.pending, (state) => {
                state.loadingRFIDCards = true;
            })
            .addCase(insertNewRFIDCardAction.fulfilled, (state) => {
                state.loadingRFIDCards = false;
            })
            .addCase(insertNewRFIDCardAction.rejected, (state) => {
                state.loadingRFIDCards = false;
            })
            .addCase(deleteRFIDCard.fulfilled, (state, {meta}) => {
                let content = state.foundRFIDCards.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(rfd => rfd.cardNumber === meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(updateRFIDCard.fulfilled, (state, {payload}) => {
                let content = state.foundRFIDCards.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(rfd => rfd.cardNumber === payload.cardNumber);
                    if (index !== -1) {
                        content[index] = payload.data;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(deleteRFIDCard.rejected, (state) => {
                state.loadingRFIDCards = false;
            })
            .addCase(extendRFIDCard.pending, (state) => {
                state.loadingRFIDCards = true;
            })
            .addCase(extendRFIDCard.fulfilled, (state, { payload }) => {
                const currentState = current(state.allRFIDCards);
                let arr = [...currentState.content];
                if (payload.data.cardNumber) {
                    let index = arr.findIndex((i) => i.cardNumber === payload.data.cardNumber);
                    if (index !== -1) {
                        arr[index] = payload.data;
                        state.allRFIDCards = {
                            ...currentState,
                            content: arr,
                        };
                    }
                }
                state.loadingRFIDCards = false;
            })
            .addCase(extendRFIDCard.rejected, (state) => {
                state.loadingRFIDCards = false;
            });
    },
});

export const { specialLoading } = RFIDSlice.actions;

export const RFIDReducer = RFIDSlice.reducer;


