import React, {useEffect, useLayoutEffect, useState} from "react";
import {dispatch, useSelector} from "../../redux/store";
import {useSnackbar} from "notistack";
import moment from "moment/moment";
import {child, get} from "firebase/database";
import {
    getIniLoggedUser,
    getSessionAppVersion,
    getSessionSelectedLocation,
    setSessionSelectedLocation
} from "../../helper/session";

import {syncAllInvoices} from "../../redux/slices/synchronization";
import {fetchAllUserLicense, updateIsExpired} from "../../redux/slices/license";
import {fetchAllLocations} from "../../redux/slices/locations";
import {getIsTaxFree} from "../../redux/slices/services";
import {fetchAdvertisementText, fetchSettings, fetchTIN, fetchUserLogoUrl} from "../../redux/slices/settings";

import {Box} from "@mui/material";
import {Outlet, useNavigate} from "react-router-dom";
import Main from "./Main";
import Header from "./header";
import NavVertical from "./nav/NavVertical";
import LicenseExpiredDialog from "../../components/licenseExpiredDialog";
import CacheDialog from "../../components/cacheDialog";
import LoadingModal from "../../components/loading-modal";
import LocationDialog from "../../components/LocationDialog";

import {ALL_LICENSE, ONE_DAY_SECONDS_TIMEOUT, ONE_MINUTE_SECONDS_TIMEOUT} from "../../constants";
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import {useLoading} from "../../context/Loading";
import {setDefaultUserSettings} from "../../redux/slices/userSettings";
import {PATH_DASHBOARD} from "../../routes/path";
import {fetchAllMergedItems} from "../../redux/slices/items";
import NotificationDialog from "../../components/KisokNotifications";
import {FirebaseProvider} from "../../context/FirebaseMessages";
import {fetchTaxCoreStatus} from "../../redux/slices/taxCore";
import {getAllVATS} from "../../redux/slices/firebase";

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const {loading} = useLoading();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const {isWebLicenseExpired} = useSelector(((state) => state.license));
    const {settings, userImage, advertisementText} = useSelector(state => state.settings);

    const {fetched} = useSelector(state => state.userSettings);

    const [open, setOpen] = useState(false);
    const [openVersionConfirm, setOpenVersionConfirm] = useState(false);
    const [openLocationDialog, setOpenLocationDialog] = useState(false);

    const primaryUser = getIniLoggedUser();
    const isPrimary = primaryUser?.uid === AUTH.currentUser.uid

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleKeyboardPress = (e, isPrimary, navigate) => {
        if (e.key === 'F10' && isPrimary) {
            e.preventDefault();
            navigate(PATH_DASHBOARD.general.stationServices);
        }

        if (e.shiftKey && e.key === 'D' && isPrimary) {
            e.preventDefault();
            navigate(PATH_DASHBOARD.preview.allotments);
        }

        if (e.shiftKey && e.key === 'C' && isPrimary) {
            e.preventDefault();
            navigate(PATH_DASHBOARD.preview.priceList);
        }
    };

    const {allItemsList} = useSelector(state => state.items);

    useEffect(() => {
        if (allItemsList.length === 0) {
            dispatch(fetchAllMergedItems()).unwrap().catch(reason => {
                console.error("fetchAllMergedItems", reason);
                enqueueSnackbar("Doslo je do greske", {variant: "error"});
            });
        }
        // eslint-disable-next-line
    }, [allItemsList.length]);


    useEffect(() => {
        dispatch(getAllVATS()).unwrap().catch(reason => {
            console.error("fetchTaxCoreStatus", reason);
            enqueueSnackbar("Greška prilikom očitavanja poreskih stopa!", {variant: "error"});
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!settings) {
            dispatch(fetchSettings()).unwrap().catch(reason => {
                console.error("fetchSettings", reason);
                enqueueSnackbar("Greška prilikom očitavanja podešavanja!", {variant: "error"});
            });
        }
        // eslint-disable-next-line
    }, [settings]);

    useEffect(() => {
        if (!fetched) {
            dispatch(setDefaultUserSettings());
        }
    }, [fetched, isPrimary])

    useEffect(() => {
        dispatch(fetchTIN()).unwrap().catch(reason => {
            console.error("fetchTIN", reason);
            enqueueSnackbar("Greška prilikom očitavanja pib-a!", {variant: "error"});
        });
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        if (userImage === null) {
            dispatch(fetchUserLogoUrl()).unwrap().catch((error) => {
                if (error.code !== "storage/object-not-found") {
                    console.error("fetchUserLogoUrl", error);
                    enqueueSnackbar(`Greška prilikom očitavanja loga: ${error.message}`, {
                        variant: "error",
                    });
                }
            });
        }
        // eslint-disable-next-line
    }, [userImage]);

    useEffect(() => {
        if (advertisementText === undefined) {
            dispatch(fetchAdvertisementText()).unwrap().catch(error => {
                console.error("fetchAdvertisementText", error);
                enqueueSnackbar("Greška prilikom očitavanja reklamnog teksta", {variant: "error"});
            });
        }
        // eslint-disable-next-line
    }, [advertisementText]);

    useLayoutEffect(() => {
        if (isPrimary) {
            dispatch(fetchAllLocations()).unwrap().then(response => {
                if (response.length === 1) {
                    setSessionSelectedLocation(response[0]);
                } else {
                    if (getSessionSelectedLocation() === undefined) {
                        setOpenLocationDialog(true);
                    } else {
                        let selected = response.some(location => location.PAC === getSessionSelectedLocation().PAC);
                        if (!selected) {
                            setOpenLocationDialog(true);
                        }
                    }
                }
            }).catch(reason => {
                console.error("fetchAllLocations", reason);
                enqueueSnackbar("Nije moguće očitati lokacije", {variant: "error"});
            });
        }
        // eslint-disable-next-line
    }, [isPrimary]);

    useEffect(() => {
        if (AUTH.currentUser.email !== "graduzice@becejprevoz.com") {
            dispatch(syncAllInvoices()).unwrap().catch(reason => {
                console.error("syncAllInvoices", reason);
                enqueueSnackbar("Greška prilikom sinhronizacije podataka!", {variant: "error"});
            });
        }
        // eslint-disable-next-line
    }, []);

    const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose}/>;

    const onLocationChosen = (e, location) => {
        setSessionSelectedLocation(location);
        setOpenLocationDialog(false);
        enqueueSnackbar("Uspešno dodeljena lokacija", {variant: "success"});
    };

    useEffect(() => {
        let sessionVersion = getSessionAppVersion();
        get(child(dbRef, `public/webAppData/version`)).then(value => {
            if (sessionVersion !== null || sessionVersion !== value.val()) {
                setOpenVersionConfirm(true);
            }
        });
    }, []);

    useEffect(() => {
        dispatch(getIsTaxFree())
    }, [])
    useEffect(() => {
        dispatch(fetchAllUserLicense()).unwrap().then(response => {
            if (response) {
                let webLicense = response.filter(license => license.productID === ALL_LICENSE.webESIR);
                let isWebLicenseExpired = moment(webLicense[0].expiredDate).isBefore(moment.now());
                if (!isWebLicenseExpired) {
                    let timeTrigger = dateDiffInMinutes(webLicense[0].expiredDate);
                    if (ONE_DAY_SECONDS_TIMEOUT > timeTrigger * ONE_MINUTE_SECONDS_TIMEOUT) {
                        const timer = setTimeout(() => {
                            dispatch(updateIsExpired());

                        }, timeTrigger * ONE_MINUTE_SECONDS_TIMEOUT);
                        return () => clearTimeout(timer);
                    }
                }
            }
        });
    }, [isWebLicenseExpired]);

    function dateDiffInMinutes(licenceTime) {
        let a = moment(new Date());
        let b = moment(licenceTime);
        return b.diff(a, "minutes");
    }

    return (
        <>
            {loading && <LoadingModal/>}
            <Header onOpenNav={handleOpen}/>
            <FirebaseProvider>
                <NotificationDialog/>
                <Box
                    sx={{
                        display: {lg: "flex"},
                        minHeight: {lg: 1}
                    }}>
                    {renderNavVertical}

                    <Main>
                        <Outlet/>
                    </Main>
                </Box>

            </FirebaseProvider>
            {openLocationDialog && <LocationDialog
                open={openLocationDialog}
                handleLocationClick={onLocationChosen}/>}
            {isWebLicenseExpired && <LicenseExpiredDialog open={isWebLicenseExpired}/>}
            {openVersionConfirm && <CacheDialog open={openVersionConfirm} setOpen={setOpenVersionConfirm}/>}</>
    );
}
