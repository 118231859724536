import PropTypes from 'prop-types';
// @mui
import {List, Stack} from '@mui/material';
//
import {StyledSubheader} from './styles';
import NavList from './NavList';
import {getIniLoggedUser, getSessionOperator} from "../../../helper/session";
import {AUTH} from "../../../auth/FirebaseContext";
import {useSelector} from "../../../redux/store";

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
    sx: PropTypes.object,
    data: PropTypes.array,
};

export default function NavSectionVertical({data, sx, ...other}) {
    const {
        forbiddenPages
    } = useSelector(state => state.userSettings);
    const OPERATOR = getSessionOperator().group;
    const primaryUser = getIniLoggedUser();
    const isPrimary = primaryUser?.uid === AUTH.currentUser.uid;

    if (forbiddenPages) {
        data = data.map(value => {
            const items = [];
            if (value.items) {
                value.items.forEach((item) => {
                    if (!forbiddenPages.includes(item.path)) {
                        items.push(item);
                    }
                })
            }
            return {
                ...value,
                items: items
            }
        })
    }
    data = data.filter(item => {
        if (item.uid) {
            return isPrimary && item.uid === AUTH.currentUser.uid
        }
        return true;
    })
    data = filterNavByRole(data, OPERATOR);
    // Filter the main data
    data = data.filter(value => {
        if (value.needPrimaryUser === undefined || value.needPrimaryUser === false) {
            return true
        }
        return isPrimary;
    })

    return (
        <Stack sx={sx} {...other}>
            {data.map((group) => {
                const key = group.subheader || group.items[0].title;
                let allItems = group?.items || [];
                allItems = allItems.filter(it=> {
                    if (it.needPrimaryUser === undefined || it.needPrimaryUser === false) {
                        return true
                    }
                    return isPrimary;
                })

                // Filter the items within each group
                allItems = allItems.map(item => {
                    const items = [...(item.children || [])]
                    const newItems = items.filter(subItem => {
                        if (!subItem.roles) {
                            return true;
                        }
                        return subItem.roles.includes(OPERATOR)
                    })
                    return {
                        ...item,
                        items: newItems
                    }
                })

                // If no items are left after filtering, skip this group
                if (allItems.length === 0) {
                    return null;
                }
                return (
                    <List key={key} disablePadding sx={{px: 2}}>
                        {group.subheader && <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>}

                        {allItems.map((list) => (
                            <NavList operator={OPERATOR} key={list.title + list.path} data={list} depth={1}
                                     hasChild={!!list.children} isPrimary={isPrimary}/>
                        ))}
                    </List>
                );
            })}
        </Stack>
    );
}

const filterNavByRole = (navItems, operator) => {
    return navItems.map(item => {
        if(item.roles && !item.roles.includes(operator)){
            return {
                ...item,
                items: []
            };
        }
        const subItems = filterItemsByRole(item.items || [], operator);
        const filteredSubItems = subItems.map(subItem => {
            if (subItem.children === undefined || subItem.children === null) {
                if (haveRole(subItem.roles, operator)) {
                    return subItem
                }
            }
            const children = filterItemsByRole(subItem.children || [], operator);
            return {
                ...subItem,
                children: children
            };
        });

        return {
            ...item,
            items: filteredSubItems
        };
    })
};

const filterItemsByRole = (items, operatorGroup) => {
    return items.filter(item => {
        return haveRole(item.roles, operatorGroup)
    });
};

const haveRole = (roles, operatorGroup) => {
    if (roles === undefined || roles === null) {
        return true;
    }
    return roles.includes(operatorGroup);
}
