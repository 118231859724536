import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    changeStatusDiscountApi,
    deleteDiscountApi,
    findTicketTypeBySearchDataAPI,
    getAllTicketTypeAPI,
    insertWithMultipleTripsSelectedAPI,
    searchDiscountByTripIdAPI,
    searchTicketTypeAPI,
    searchTicketTypeByOperatorIdAPI,
    updateDiscountAPI, updateWithMultipleTripsSelectedAPI
} from "../../api/ticketType/ticketType";
import {parsePaginationParams} from "../../utils/other";

export const findTicketTypeBySearchData = createAsyncThunk('findTicketTypeBySearchData', async (data) => {
    return await findTicketTypeBySearchDataAPI(data)
})

export const getAllTicketType =
    createAsyncThunk('getAllTicketType', async (params) => {
        const parsedParams = parsePaginationParams(params)
        return (await getAllTicketTypeAPI(parsedParams)).data
    })

export const deleteTicketType = createAsyncThunk('deleteTicketType', async (id, {rejectWithValue}) => {
    try {
        const response = await deleteDiscountApi(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const changeDiscountStatus = createAsyncThunk('changeDiscountStatus', async (id, {rejectWithValue}) => {
    try {
        const response = await changeStatusDiscountApi(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const postDiscountWithTrips = createAsyncThunk('postTicketType', async (payload) => {
    return insertWithMultipleTripsSelectedAPI(payload);
})

export const updateWithMultipleTripsSelected = createAsyncThunk('updateWithMultipleTripsSelected', async (payload) => {
    return updateWithMultipleTripsSelectedAPI(payload);
})

export const updateDiscount = createAsyncThunk('updateDiscount', async (payload) => {
    return updateDiscountAPI(payload)
})

export const searchDiscount =
    createAsyncThunk('searchDiscount', async (payload) => {
        return (await searchTicketTypeAPI(payload)).data
    })

export const searchDiscountByProvidedOperatorId =
    createAsyncThunk('searchDiscountByProvidedOperatorId', async (operatorId) => {
        return (await searchTicketTypeByOperatorIdAPI(operatorId)).data
    })

export const searchDiscountByTripId =
    createAsyncThunk('searchDiscountByTripId', async (data) => {
        return (await searchDiscountByTripIdAPI(data)).data
    })

const initialState = {
    foundDiscounts: [],
    loadingDiscounts: false,
}

const discountSlice = createSlice({
    name: 'discounts',
    initialState,
    reducers: {
        specialTicketTypeLoading: (state, {payload}) => {
            if (payload) {
                state.loadingDiscounts = payload
            }
        },
        searchTicketTypeSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundDiscounts = payload;
                state.loadingDiscounts = false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findTicketTypeBySearchData.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(findTicketTypeBySearchData.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.foundDiscounts = payload.data;
                }
                state.loadingDiscounts = false;
            })
            .addCase(findTicketTypeBySearchData.rejected, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(getAllTicketType.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(getAllTicketType.fulfilled, (state, {payload}) => {
                state.foundDiscounts = payload;
                state.loadingDiscounts = false;
            })
            .addCase(getAllTicketType.rejected, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(searchDiscount.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(searchDiscount.fulfilled, (state, {payload}) => {
                state.loadingDiscounts = false;
            })
            .addCase(searchDiscount.rejected, (state) => {
                state.loadingDiscounts = false;
            })
            .addCase(searchDiscountByProvidedOperatorId.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(searchDiscountByProvidedOperatorId.fulfilled, (state, {payload}) => {
                state.loadingDiscounts = false;
            })
            .addCase(searchDiscountByProvidedOperatorId.rejected, (state) => {
                state.loadingDiscounts = false;
            })
            .addCase(searchDiscountByTripId.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(searchDiscountByTripId.fulfilled, (state, {payload}) => {
                state.loadingDiscounts = false;
            })
            .addCase(searchDiscountByTripId.rejected, (state) => {
                state.loadingDiscounts = false;
            })
            .addCase(deleteTicketType.fulfilled, (state, action) => {
                let content = state.foundDiscounts.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(vehicle => vehicle.id === action.meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                        state.foundDiscounts.totalElements -= 1;
                        state.foundDiscounts.numberOfElements = content.length;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(changeDiscountStatus.fulfilled, (state, action) => {
                const content = state.foundDiscounts.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(ticketType => ticketType.id === action.meta.arg);
                    if (index !== -1) {
                        content[index].active = !content[index].active;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
    }
});

export const {searchTicketTypeSlice, specialTicketTypeLoading} = discountSlice.actions;

export default discountSlice.reducer;
