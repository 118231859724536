import {customAxiosBE} from "../config";
import {clearEmptyString, parsePaginationParams} from "../../utils/other";
import axios from "axios";

// OVO SU POPUSTI

export async function getAllTicketTypeAPI(payload) {
    return customAxiosBE.get(`/ticketType?${payload}`)
}

export async function findTicketTypeBySearchDataAPI(payload) {
    const {name, operatorName} = payload?.data
    if (!name && !operatorName) {
        return undefined
    }
    return customAxiosBE.post(`/ticketType/search?page=${payload.page}&size=${payload.rowsPerPage}`, {
        name: clearEmptyString(name),
        operatorName: clearEmptyString(operatorName),
    })
}

export async function getDiscountByIdApi(id) {
    return customAxiosBE.get(`/ticketType/${id}`)
}

export async function insertDiscountAPI(data) {
    return customAxiosBE.post(`/ticketType`, data)
}

export async function insertWithMultipleTripsSelectedAPI(data) {
    return customAxiosBE.post(`/ticketType/insertWithMultipleTripsSelected`, data)
}

export async function updateWithMultipleTripsSelectedAPI(data) {
    return customAxiosBE.put(`/ticketType/updateWithMultipleTripsSelected`, data)
}

export async function updateDiscountAPI(data) {
    return customAxiosBE.put(`/ticketType`, data)
}

export async function deleteDiscountApi(id) {
    return customAxiosBE.delete(`/ticketType/${id}`)
}

export async function changeStatusDiscountApi(id) {
    return customAxiosBE.put(`/ticketType/${id}/status`)
}

export async function searchTicketTypeByOperatorIdAPI(operatorId) {
    return customAxiosBE.get(`/ticketType/search/operator/${operatorId}`)
}

export async function getAllTicketsTypeByOperatorIdAPI(tripId) {
    return customAxiosBE.get(`/ticketType/byOperator/${tripId}`)
}

export async function searchDiscountByTripIdAPI(data) {
    const {tripId} = data
    return customAxiosBE.get(`/ticketType/search/tripId/${tripId}`)
}

export async function getDiscountForSaleAPI(data) {
    return customAxiosBE.post(`/ticketType/search/sale`, data)
}

export async function searchTicketTypeAPI(data, cancelToken) {
    let response
    const {name, operatorName} = data
    const params = parsePaginationParams(data)
    await customAxiosBE
        .post(`/ticketType/search?${params}`, {
            name: name,
            operatorName: operatorName,
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}


export async function getAllDisabilitiesAPI(params) {
    const parsedParams = parsePaginationParams(params)
    return customAxiosBE.get(`/ticketType/default?${parsedParams}`)
}

export async function getLineNamesByTicketTypeId(data) {
    return customAxiosBE.post(`/getLineNamesByTicketType`, data)
}


export async function searchTicketTypeByOperatorAPI(data, cancelToken) {
    let response
    const {name, operatorName} = data
    const params = parsePaginationParams(data)
    await customAxiosBE
        .post(`/ticketType/search/bySubOperator?${params}`, {
            name: name,
            operatorName: operatorName,
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}
