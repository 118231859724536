import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    deleteSalesActionTypeAPI,
    getAllSalesActionTypeAPI,
    insertSalesActionTypeAPI,
    updateSalesActionTypeAPI
} from "../../api/salesActionType";

export const fetchAllSalesActionType = createAsyncThunk('fetchAllSalesActionType', async (data) => {
    return await getAllSalesActionTypeAPI(data)
})

export const insertNewSalesActionType = createAsyncThunk('insertNewSalesActionType', async (data) => {
    return await insertSalesActionTypeAPI(data)
})

export const deleteSalesActionType = createAsyncThunk('deleteSalesActionTypeNomAPI', async (id, {rejectWithValue}) => {
    try {
        const response = await deleteSalesActionTypeAPI(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const updateSalesActionType = createAsyncThunk('updateSalesActionType', async (data) => {
    return await updateSalesActionTypeAPI(data)
})

const initialState = {
    foundSalesActionType: [],
    loadingSalesActionType: false,
}

const actionsSaleType = createSlice({
    name: 'salesActionType',
    initialState,

    reducers: {
        specialSaleActionTypeLoading: (state, { payload }) => {
            if (payload) {
                state.loadingSalesActionType = payload;
            }
        },
        searchActionsSaleType: (state, { payload }) => {
            if (payload?.content) {
                state.foundSalesActionType = payload;
                state.loadingSalesActionType = false;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllSalesActionType.pending, (state) => {
                state.loadingSalesActionType = true;
            })
            .addCase(fetchAllSalesActionType.fulfilled, (state, { payload }) => {
                state.foundSalesActionType = payload.data;
                state.loadingSalesActionType = false;
            })
            .addCase(fetchAllSalesActionType.rejected, (state) => {
                state.loadingSalesActionType = false;
            })
            .addCase(insertNewSalesActionType.pending, (state) => {
                state.loadingSalesActionType = true;
            })
            .addCase(insertNewSalesActionType.fulfilled, (state) => {
                state.loadingSalesActionType = false;
            })
            .addCase(insertNewSalesActionType.rejected, (state) => {
                state.loadingSalesActionType = false;
            })
            .addCase(deleteSalesActionType.fulfilled, (state, {meta}) => {
                let content = state.foundSalesActionType.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(saleAction => saleAction.id === meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                        state.foundSalesActionType.totalElements -= 1;
                        state.foundSalesActionType.numberOfElements = content.length;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
    }
});

export const { searchActionsSaleType, specialSaleActionTypeLoading } = actionsSaleType.actions;

export default actionsSaleType.reducer;
