import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    deleteRateCardsAPI,
    getAllRateCardsAPI,
    getAllRateCardsWithOperatorAPI,
    insertRateCardsAPI,
    updateRateCardAPI
} from "../../api/rateCard";

export const fetchAllRateCards = createAsyncThunk('fetchAllRateCards', async (payload) => {
    return await getAllRateCardsAPI(payload)
})
export const fetchAllRateCardsWithOperator = createAsyncThunk('fetchAllRateCardsWithOperator', async (payload) => {
    return await getAllRateCardsWithOperatorAPI(payload)
})

export const deleteRateCardById = createAsyncThunk('deleteRateCardById', async (rateCardId, {rejectWithValue}) => {
    try {
        const response = await deleteRateCardsAPI(rateCardId);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
});

export const postRateCard = createAsyncThunk('postRateCard', async (payload, thunkAPI) => {
    try {
        return await insertRateCardsAPI(payload);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
})

export const updateRateCard = createAsyncThunk('updateRateCard', async (payload, thunkAPI) => {
    try {
        return await updateRateCardAPI(payload);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
})

const initialState = {
    foundRateCards: [],
    loadingRateCards: false,
}

export const rateCardsSlice = createSlice({
    name: 'rateCards',
    initialState,
    reducers: {
        specialLoading: (state, { payload }) => {
            state.loadingRateCards = payload;
        },
        searchRateCardSlice: (state, { payload }) => {
            if (payload?.content) {
                state.foundRateCards = payload;
                state.loadingRateCards = false;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRateCards.pending, (state) => {
                state.loadingRateCards = true;
            })
            .addCase(fetchAllRateCards.fulfilled, (state, { payload }) => {
                state.loadingRateCards = false;
                state.foundRateCards = payload.data;
            })
            .addCase(fetchAllRateCards.rejected, (state) => {
                state.loadingRateCards = false;
            })
            .addCase(postRateCard.fulfilled, (state,{payload}) => {
                let content = state.foundRateCards.content;
                if (Array.isArray(content)) {
                    content.push(payload.data)
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(updateRateCard.fulfilled, (state, {payload}) => {
                let content = state.foundRateCards.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex((rateCard) => rateCard.id === payload.data.id);
                    if (index !== -1) {
                        state.foundRateCards.content[index] = payload.data}
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(fetchAllRateCardsWithOperator.pending, (state) => {
                state.loadingRateCards = true;
            })
            .addCase(fetchAllRateCardsWithOperator.fulfilled, (state, { payload }) => {
                state.foundRateCards = payload.data;
                state.loadingRateCards = false;
            })
            .addCase(fetchAllRateCardsWithOperator.rejected, (state) => {
                state.loadingRateCards = false;
            })
            .addCase(deleteRateCardById.fulfilled, (state, { meta }) => {
                let content = state.foundRateCards.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex((rateCard) => rateCard.id === meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                        state.foundRateCards.totalElements -= 1;
                        state.foundRateCards.numberOfElements = content.length;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
    },
});

export const {searchRateCardSlice, specialLoading} = rateCardsSlice.actions;
export const rateCardsReducer = rateCardsSlice.reducer
