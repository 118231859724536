import PropTypes from "prop-types";
import {useState} from "react";

import {IconButton, Stack, TableCell, TableRow, Tooltip} from "@mui/material";
import Iconify from "../../../components/Iconify";
import Label from "../../../components/label";
import {useLocales} from "../../../locales";
import moment from "moment/moment";
import {deactivateAction, deleteAction} from "../../../redux/slices/actions";
import {ICONS_NAME} from "../../../constants/icons";
import {useDispatch} from "react-redux";

ActionsValueTableRow.propTypes = {
    row: PropTypes.object,
    onEditRow: PropTypes.func,
};

export default function ActionsValueTableRow({row, onEditRow, enqueueSnackbar}) {
    const dispatch = useDispatch();
    const {translate} = useLocales();
    const {amount, vat, dateFrom, dateTo, isAbsolute, name, id} = row;
    const [isDeleting, setIsDeleting] = useState(false)
    const [isDeactivating, setIsDeactivating] = useState(false)

    const onDelete = () => {
        setIsDeleting(true)
        dispatch(deleteAction(id))
            .unwrap()
            .then((successDeleteResponse) => {
                enqueueSnackbar(translate(successDeleteResponse), {variant: 'success'});
            })
            .catch((deleteErrorResponse) => {
                enqueueSnackbar(translate(deleteErrorResponse), {variant: 'warning'});
            }).finally(() => {
            setIsDeleting(false)
        });
    };
    const parsedDateTo = moment(dateTo, 'DD.MM.YYYY').startOf('day');
    const parsedDateFrom = moment(dateFrom, 'DD.MM.YYYY').startOf('day');
    const today = moment().startOf('day');
    const onDeactivate = () => {
        if (parsedDateFrom.isAfter(today)) {
            return enqueueSnackbar("Ne možete pasivizirati akciju koja nije započetna. Izmenite datum ili je obrišite", {variant: "info"})
        }
        setIsDeactivating(true)
        dispatch(deactivateAction(id))
            .unwrap()
            .then((successDeactivateResponse) => {
                enqueueSnackbar(translate(successDeactivateResponse), {variant: 'success'});
            })
            .catch((deactivateErrorResponse) => {
                enqueueSnackbar(translate(deactivateErrorResponse), {variant: 'warning'});
            }).finally(() => {
            setIsDeactivating(false)
        });
    };

    const isDateToFuture = parsedDateTo.isAfter(today);

    return (
        <>
            <TableRow hover>

                <TableCell align="left">{name}</TableCell>
                <TableCell align="left">
                    <Label
                        variant="soft"
                        color={isAbsolute ? 'success' : 'info'}
                    >
                        {isAbsolute ? translate("absolute") : translate("relative")}
                    </Label>
                </TableCell>
                <TableCell align="left">{amount}</TableCell>
                <TableCell align="left">{vat}</TableCell>
                <TableCell align="left">{dateFrom}</TableCell>
                <TableCell align="left">{dateTo || ""}</TableCell>
                <TableCell align="center">
                    <Stack direction="row" spacing={1}>
                        <Tooltip title={translate("editTooltip")} arrow>
                            <IconButton onClick={onEditRow} aria-label="Edit">
                                <Iconify icon={ICONS_NAME.edit}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={translate("deactivateTooltip")} arrow>
                            <IconButton disabled={!isDateToFuture} onClick={onDeactivate} aria-label="Deactivate">
                                {isDeactivating ? (
                                    <Iconify icon={ICONS_NAME.spinner}/>
                                ) : (
                                    <Iconify icon={ICONS_NAME.cancel}/>
                                )}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={translate("deleteTooltip")} arrow>
                            <IconButton onClick={onDelete} aria-label="Delete" disabled={isDeleting}>
                                {isDeleting ? (
                                    <Iconify icon={ICONS_NAME.spinner}/>
                                ) : (
                                    <Iconify icon={ICONS_NAME.trash}/>
                                )}
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </TableCell>
            </TableRow>
        </>
    )
}
