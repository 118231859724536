import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    changeStatusAPI,
    deleteVehicleAPI,
    getAllVehiclesAPI,
    getBusByIdApi,
    insertVehiclesAPI,
    updateVehiclesAPI
} from "../../api/vehicle";

export const fetchAllBuses = createAsyncThunk('fetchAllBuses', async (data) => {
    return await getAllVehiclesAPI(data)
})

export const findBusById = createAsyncThunk('findBusById', async (id) => {
    return await getBusByIdApi(id)
})

export const changeBusStatus = createAsyncThunk('changeBusStatus', async (vehicleId, {rejectWithValue}) => {
    try {
        const response = await changeStatusAPI(vehicleId);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const deleteBus = createAsyncThunk('deleteBus', async (vehicleId, {rejectWithValue}) => {
    try {
        const response = await deleteVehicleAPI(vehicleId);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
});


export const postBus = createAsyncThunk(
    'bus/postBus',
    async (payload) => {
        return insertVehiclesAPI(payload);
    }
);

export const updateBus = createAsyncThunk(
    'bus/updateBus',
    async (payload) => {
        return updateVehiclesAPI(payload);
    }
);

const initialState = {
    foundVehicles: undefined,
    loadingBuses: false
}

const busesSlice = createSlice({
    name: 'buses',
    initialState,
    reducers: {
        specialBusLoading: (state, {payload}) => {
            if (payload) {
                state.loadingBuses = payload
            }
        },
        searchBusSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundVehicles = payload;
                state.loadingBuses = false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBuses.pending, (state) => {
                state.loadingBuses = true;
            })
            .addCase(fetchAllBuses.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.foundVehicles = payload.data;
                }
                state.loadingBuses = false;
            })
            .addCase(fetchAllBuses.rejected, (state) => {
                state.loadingBuses = false;
            })
            .addCase(changeBusStatus.fulfilled, (state, action) => {
                const content = state.foundVehicles.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(vehicle => vehicle.id === action.meta.arg);
                    if (index !== -1) {
                        content[index].isActive = !content[index].isActive;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(deleteBus.fulfilled, (state, action) => {
                let content = state.foundVehicles.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(vehicle => vehicle.id === action.meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                        state.foundVehicles.totalElements -= 1;
                        state.foundVehicles.numberOfElements = content.length;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(postBus.pending, (state) => {
                state.loadingBuses = true;
            })
            .addCase(postBus.fulfilled, (state) => {
                state.loadingBuses = false;
            })
            .addCase(postBus.rejected, (state) => {
                state.loadingBuses = false;
            })
            .addCase(updateBus.pending, (state) => {
                state.loadingBuses = false;
            })
            .addCase(updateBus.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateBus.rejected, (state) => {
                state.loadingBuses = false;
            });
    },
});

export const {searchBusSlice, specialBusLoading} = busesSlice.actions;
export default busesSlice.reducer;
