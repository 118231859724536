import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    deactivateActionAPI,
    deleteActionsAPI,
    getAllActionsAPI,
    insertActionsAPI,
    updateActionsAPI
} from "../../api/actions";
import moment from "moment";

export const fetchAllActions = createAsyncThunk('fetchAllActions', async (data) => {
    return await getAllActionsAPI(data)
})

export const insertNewActions = createAsyncThunk('insertNewActions', async (data) => {
    return await insertActionsAPI(data)
})

export const deleteAction = createAsyncThunk('deleteAction', async (id, {rejectWithValue}) => {
    try {
        const response = await deleteActionsAPI(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const deactivateAction = createAsyncThunk('deactivateAction', async (id, {rejectWithValue}) => {
    try {
        const response = await deactivateActionAPI(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const updateActions = createAsyncThunk('updateActions', async (data) => {
    return await updateActionsAPI(data)
})


const initialState = {
    foundActions: [],
    loadingActions: false,
}

export const actionsSlice = createSlice({
    name: 'actions',
    initialState,
    reducers: {
        specialActionsLoading: (state, {payload}) => {
            if (payload) {
                state.loadingActions = payload;
            }
        },
        searchActions: (state, {payload}) => {
            if (payload?.content) {
                state.foundActions = payload;
                state.loadingActions = false;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllActions.pending, (state) => {
                state.loadingActions = true;
            })
            .addCase(fetchAllActions.fulfilled, (state, {payload}) => {
                state.allActions = payload.data;
                state.foundActions = payload.data;
                state.loadingActions = false;
            })
            .addCase(fetchAllActions.rejected, (state) => {
                state.loadingActions = false;
            })
            .addCase(insertNewActions.pending, (state) => {
                state.loadingActions = true;
            })
            .addCase(insertNewActions.fulfilled, (state) => {
                state.loadingActions = false;
            })
            .addCase(insertNewActions.rejected, (state) => {
                state.loadingActions = false;
            })
            .addCase(deleteAction.fulfilled, (state, {meta}) => {
                let content = state.foundActions.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(act => act.id === meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                        state.foundActions.totalElements -= 1;
                        state.foundActions.numberOfElements = content.length;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(deactivateAction.fulfilled, (state, action) => {
                const content = state.foundActions.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(act => act.id === action.meta.arg);
                    if (index !== -1) {
                        const yesterday = moment().subtract(1, 'days').startOf('day').format('DD.MM.YYYY');
                        content[index].dateTo = yesterday;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
    }
});

export const {searchActions, specialActionsLoading} = actionsSlice.actions;

export default actionsSlice.reducer;

