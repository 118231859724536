import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    deleteLocationAPI,
    getAllLocationsAPI,
    getLocationByIdAPI,
    insertLocationAPI,
    updateLocationAPI
} from "../../api/locations";

export const fetchAllStationsLocation =
    createAsyncThunk('fetchAllStationsLocation', async (data) => {
        return getAllLocationsAPI(data);
    })

export const deleteLocation = createAsyncThunk('deleteLocation', async (id, {rejectWithValue}) => {
    try {
        const response = await deleteLocationAPI(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const postLocation = createAsyncThunk('postLocation', async (payload, {rejectWithValue}) => {
    try {
        const response = await insertLocationAPI(payload);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const updateLocation = createAsyncThunk('updateLocation', async (payload, {rejectWithValue}) => {
    try {
        const response = await updateLocationAPI(payload);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const getLocationById = createAsyncThunk('getLocationById', async (payload) => {
    return getLocationByIdAPI(payload);
})

const initialState = {
    stationLocations: [],
    stationLocation: null,
    loadingStationsLocations: false,
    searchParam: ""
}

const stationLocationsSlice = createSlice({
    name: 'apiStation',
    initialState,
    reducers: {
        setSearchParam: (state, action) => {
            state.searchParam = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllStationsLocation.pending, (state) => {
                state.loadingStationsLocations = true;
            })
            .addCase(fetchAllStationsLocation.fulfilled, (state, {payload}) => {
                state.stationLocations = payload.data.content;
                state.loadingStationsLocations = false;
            })
            .addCase(fetchAllStationsLocation.rejected, (state) => {
                state.loadingStationsLocations = false;
            })
            .addCase(postLocation.fulfilled, (state, {payload}) => {
                const content = state.stationLocations;
                if (Array.isArray(content)) {
                    content.push(payload);
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(updateLocation.fulfilled, (state, {payload}) => {
                const content = state.stationLocations;
                if (Array.isArray(content)) {
                    const index = content.findIndex((stationLocations) => stationLocations.id === payload.id);
                    if (index !== -1) {
                        content[index] = payload;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(deleteLocation.fulfilled, (state, action) => {
                const content = state.stationLocations;
                if (Array.isArray(content)) {
                    const index = content.findIndex((st) => st.id === action.meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(getLocationById.pending, (state) => {
                state.loadingStationsLocations = true;
            })
            .addCase(getLocationById.fulfilled, (state, {payload}) => {
                state.stationLocations = payload.data.content;
                state.loadingStationsLocations = false;
            })
            .addCase(getLocationById.rejected, (state) => {
                state.loadingStationsLocations = false;
            });
    }
});

export const {setSearchParam} = stationLocationsSlice.actions;
export const stationLocationsReducer = stationLocationsSlice.reducer;
