import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

RHFTextFieldPrice.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};

export default function RHFTextFieldPrice({ name, label, autoFocus, ...other }) {
    const { control } = useFormContext();

    const handleValueChange = ({ floatValue }, field) => {
        field.onChange(floatValue ?? null);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <NumericFormat
                    {...field}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale
                    decimalScale={2}
                    allowNegative={false}
                    variant="outlined"
                    size="small"
                    label={label}
                    autoFocus={autoFocus || false}
                    error={!!error}
                    helperText={error?.message}
                    onValueChange={({ floatValue }) => handleValueChange({ floatValue }, field)}
                />
            )}
        />
    );
}
