import {customAxiosBE} from "../config";
import axios from "axios";
import {parsePaginationParams} from "../../utils/other";

export async function getAllSalesActionTypeAPI(payload) {
    return customAxiosBE.get(`/salesActionType?page=${payload.page}&size=${payload.rowsPerPage}`)
}

export async function getSalesActionTypeAPI(id) {
    return customAxiosBE.get(`/salesActionType/${id}`)
}

export async function insertSalesActionTypeAPI(data){
    return customAxiosBE.post(`/salesActionType`, data)
}
export async function updateSalesActionTypeAPI(data){
    return customAxiosBE.put(`/salesActionType`, data)
}

export async function deleteSalesActionTypeAPI(id){
    return customAxiosBE.delete(`/salesActionType/${id}`)
}

export const searchSalesActionTypeAPI = async (data, cancelToken) => {
    let response
    const {filterName} = data
    const params = parsePaginationParams(data)
    await customAxiosBE
        .post(`/salesActionType/search?${params}`, {
            name: filterName
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}
