import {customAxiosBE} from "../config";
import axios from "axios";

export async function getAllRFIDAPI(payload, params) {
    return customAxiosBE.post(`/rfidCard/search?${params}`, payload)
}

export async function getRFIDAPI(cardName) {
    return customAxiosBE.get(`/rfidCard/${cardName}`)
}

export async function insertRFIDAPI(data){
    return customAxiosBE.post(`/rfidCard`, data)
}

export async function insertRFIDActionAPI(data){
    return customAxiosBE.post(`/rfidCard/insertAction`, data)
}

export async function updateRFIDCardAPI(data){
    return customAxiosBE.put(`/rfidCard`, data)
}

export async function extendRFIDCardAPI(data){
    return customAxiosBE.post(`/rfidCard/extend`, data)
}

export async function deleteRFIDCardAPI(cardName){
    return customAxiosBE.delete(`/rfidCard/${cardName}`)
}

export const searchRFIDAPI = async (data, cancelToken) => {
    let response
    const {clientName, clientSurname, documentNumber, identityCard, page, rowsPerPage} = data
    await customAxiosBE
        .post(`/rfidCard/search?page=${page}&size=${rowsPerPage}`, {
            clientName: clientName,
            clientSurname: clientSurname,
            documentNumber: documentNumber,
            identityCard: identityCard
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}
