import Iconify from "../components/Iconify";

export const ICONS_NAME = {
    edit: "lets-icons:edit-duotone",
    more: "eva:more-vertical-fill",
    dropDown: "solar:point-on-map-bold-duotone",
    trash: "solar:trash-bin-minimalistic-bold-duotone",
    calendar: "solar:calendar-bold-duotone",
    clock: "uim:clock",
    bus: "solar:bus-bold-duotone",
    document: "solar:document-bold-duotone",
    add: "fluent-mdl2:add-to",
    remove: "mdi:remove",
    preview: "solar:eye-bold-duotone",
    seat: "mingcute:seat-fill",
    checked: "ph:check-duotone",
    notChecked: "lets-icons:cancel-duotone-line",
    location: "fluent:location-16-filled",
    spinner: "svg-spinners:dot-revolve",
    check: "solar:check-circle-line-duotone",
    cancel: "solar:close-circle-bold-duotone",
    linked: "solar:link-square-bold-duotone",
    infinity: "solar:infinity-bold-duotone",
    copy: "solar:copy-bold-duotone",
    checkmark: "eva:checkmark-circle-2-outline",
    questionMark: "solar:question-circle-bold-duotone",
    directionMap: "raphael:roadmap",
}

export function CalendarIconSvg({size = 20}) {
    return <Iconify icon={ICONS_NAME.calendar} width={size}/>;
}

export function DatePickerIcons({onChange}) {
    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: 'center', gap: "8px"}}>
            <Iconify icon={ICONS_NAME.calendar} width={24}/>
            <span style={{padding: 0, margin: 0, width:24, height: 24}} onClick={onChange}
                  aria-label="clear date"
            >
                <Iconify icon={ICONS_NAME.cancel}/>
            </span>
        </div>
    );
}
