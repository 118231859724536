import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {deleteRelatedHolidaysByIdAPI, insertHolidaysAPI, updateHolidaysAPI} from "../../api/holidays";
import {deletePeriodDateAPI} from "../../api/periodDate";

export const postHolidays = createAsyncThunk('postHolidays', async (payload, thunkAPI) => {
    try {
        return await insertHolidaysAPI(payload);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
})

export const updateHolidays = createAsyncThunk('updateHolidays', async (payload, thunkAPI) => {
    try {
        return await updateHolidaysAPI(payload);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
})

// BRISE SVE PERIODE ZA ISTI PRAZNIK ZAJEDNO SA PRAZNIKOM
export const deleteRelatedHoliday = createAsyncThunk('deleteRelatedHoliday', async (vehicleId, {rejectWithValue}) => {
    try {
        const response = await deleteRelatedHolidaysByIdAPI(vehicleId);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
});


// BRISE SAMO PERIOD ZA PRAZNIK, TAKODJE BRISE I PRAZNIK AKO JE TO BIO JEDINI PERIOD
export const deleteHoliday = createAsyncThunk('deleteHoliday', async (vehicleId, {rejectWithValue}) => {
    try {
        const response = await deletePeriodDateAPI(vehicleId);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
});

const initialState = {
    foundHolidays: [],
    loadingHolidays: false,
    deletingPeriodId: null,
}

export const holidaysSlice = createSlice({
    name: 'holidays',
    initialState,
    reducers: {
        specialHolidaysLoading: (state, {payload}) => {
            state.loadingHolidays = payload;
        },
        searchHolidaysSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundHolidays = payload;
                state.loadingHolidays = false;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postHolidays.pending, (state) => {
                state.loadingHolidays = true;
            })
            .addCase(postHolidays.fulfilled, (state) => {
                state.loadingHolidays = false;
            })
            .addCase(postHolidays.rejected, (state) => {
                state.loadingHolidays = false;
            })
            .addCase(updateHolidays.pending, (state) => {
                state.loadingHolidays = true;
            })
            .addCase(updateHolidays.fulfilled, (state) => {
                state.loadingHolidays = false;
            })
            .addCase(updateHolidays.rejected, (state) => {
                state.loadingHolidays = false;
            })
            .addCase(deleteRelatedHoliday.fulfilled, (state, action) => {
                let content = state.foundHolidays.content;
                if (Array.isArray(content)) {
                    const updatedContent = content.filter(
                        holiday => holiday.periodId !== action.meta.arg
                    );
                    state.foundHolidays.content = updatedContent;
                    state.foundHolidays.totalElements -= (content.length - updatedContent.length);
                    state.foundHolidays.numberOfElements = updatedContent.length;
                } else {
                    console.error('Content is not an array:', content);
                }
                state.deletingPeriodId = null;
            })
            .addCase(deleteHoliday.fulfilled, (state, action) => {
                let content = state.foundHolidays.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(holiday => holiday.id === action.meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            });
    },
});

export const {specialHolidaysLoading, searchHolidaysSlice} = holidaysSlice.actions;
export const holidaysReducer = holidaysSlice.reducer;
