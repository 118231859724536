import {customAxiosBE} from "../config";
import axios from "axios";
import {parsePaginationParams} from "../../utils/other";

export async function getAllRateAPI(payload) {
    const parsedParams = parsePaginationParams(payload)
    return customAxiosBE.get(`/rate?${parsedParams}`)
}
export async function getRateByIdAPI(id) {
    return customAxiosBE.get(`/rate/${id}`)
}

export async function getRateByStationsAndRateCardId(data){
    return customAxiosBE.post(`/rate/search?size=99999999`, data)
}
export async function findBySubOperator(data){
    return customAxiosBE.post(`/rate/findBySubOperator`, data)
}

export async function insertRateAPI(data) {
    return customAxiosBE.post(`/rate`,data)
}

export async function insertExceptionRateAPI(data) {
    return customAxiosBE.post(`/rate/saveOrUpdate`,data)
}
export async function updateRateAPI(data) {
    return customAxiosBE.put(`/rate`,data)
}

export async function deleteRateAPI(id) {
    return customAxiosBE.delete(`/rate/${id}`);
}

export const searchRateAPI = async (data, cancelToken) => {
    let response
    const {startStation, endStation, station2Id, station1Id, rateCardId} = data
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE
        .post(`/rate/search?${parsedParams}`, {
            startStation: startStation,
            endStation: endStation,
            station2Id: station2Id,
            station1Id: station1Id,
            rateCardId: rateCardId,
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}




